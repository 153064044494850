import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Content from "../components/utilities/content";
import Seo from "../components/utilities/seo";

const Post = ({
  data: {
    wpPost: post,
    wpPage: { blog },
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext,
  location,
}) => {
  const title = post.title ? post.title : blog.title;

  const banner = {
    name: "post-banner",
    background: post.featuredImage ? post.featuredImage.node : blog.background,
    subTitle: "Blog",
    title: title,
    pageContext: pageContext,
    location: location,
  };

  return (
    <>
      <Seo page={post.page} themeSettings={themeSettings} title={title} />
      <BlogElem banner={banner} pageContext={pageContext} location={location}>
        <Content className="content-blog">{post.content}</Content>
      </BlogElem>
    </>
  );
};

export const pageQuery = graphql`
  query Post($id: String!, $parentId: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        pageSchema
      }
    }
    wpPage(id: { eq: $parentId }) {
      blog {
        subTitle
        title
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          customCssClasses
        }
      }
    }
  }
`;

export default Post;
